import React, { Component } from 'react';
import CoordHash from 'lib-coordhash';
import './App.scss';

class App extends Component {

	constructor(props) {
		super(props);

		this.state = { 
			latitude: '',
			longlitude: '',
			hash: ''
		};

		this.handleLatitudeChange = this.handleLatitudeChange.bind(this);
		this.handleLonglitudeChange = this.handleLonglitudeChange.bind(this);
	}

	handleLatitudeChange(event) {
		const latitude = event.target.value;
		this.setState({latitude: latitude}, this.calculateHash);
	}

	handleLonglitudeChange(event) {
		const longlitude = event.target.value;
		this.setState({longlitude: longlitude}, this.calculateHash);
	}

	calculateHash() {
		const hash = CoordHash.hash(this.state.latitude, this.state.longlitude);
		this.setState({hash: hash});
	}

	render() {
		return (
			<div className="App">
				<p>CoordHash Demo</p>
				<label>Latitude</label>
				<input type="text" value={this.state.latitude} onChange={this.handleLatitudeChange} />
				<label>Longlitude</label>
				<input type="text" value={this.state.longlitude} onChange={this.handleLonglitudeChange} />
				<div>
					<p>Hash:</p>
					<p>{this.state.hash}</p>
				</div>
			</div>
		);
	}

}

export default App;
